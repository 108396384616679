<template>
  <b-card no-body>
    <b-card-header>
      <h4 class="mb-0">
        Recompensas em voucher
      </h4>
    </b-card-header>
    <template v-for="voucher in dataList">
      <b-row
        :key="voucher.id"
        class="px-2 mt-1"
      >
        <b-col
          cols="3"
          class="d-flex align-items-center justify-content-center"
          style="padding-right: 0 !important"
        >
          <b-avatar
            variant="light-primary"
            size="40"
          >
            <feather-icon
              size="20"
              icon="GiftIcon"
            />
          </b-avatar>
        </b-col>
        <b-col
          cols="7"
          class="d-flex align-items-center justify-content-start"
        >
          {{ voucher.name }}
        </b-col>
        <b-col
          cols="2"
          class="d-flex align-items-center justify-content-center"
        >
          <h5>
            {{ voucher.value }}
          </h5>
        </b-col>
      </b-row>
    </template>
  </b-card>
</template>

<script>

import {
  BRow,
  BCol, BCard, BCardHeader, BAvatar,
} from 'bootstrap-vue'

export default {
  name: 'FinancialCardListVoucher',
  components: {
    BRow,
    BCol,
    BAvatar,
    // BOverlay,
    BCard,
    BCardHeader,
  },
  props: {
    dataList: {
      type: Array,
      default: () => [
        {
          id: 1,
          name: 'Revisão do carro com 40%',
          value: 20,
        },
        {
          id: 2,
          name: 'Depilação axila grátis',
          value: 3,
        },
        {
          id: 3,
          name: '1 Gigacoin por indicação',
          value: 25,
        },
        {
          id: 4,
          name: '50% de desconto em qualquer produto',
          value: 2,
        },
      ],
    },
  },
}
</script>
