<template>
  <b-card title="Recompensas em dinheiro">
    <!-- chart -->
    <chartjs-component-doughnut-chart
      :height="200"
      :data="doughnutChart.data"
      :options="doughnutChart.options"
      class="mb-3"
    />
    <!--/ chart -->

    <!-- stocks -->
    <div
      v-for="(stock,index) in stockData"
      :key="stock.device"
      :class="index < stockData.length-1 ? 'mb-1':''"
      class="d-flex justify-content-between"
    >
      <div class="d-flex align-items-center">
        <feather-icon
          :icon="stock.symbol"
          size="16"
          :class="stock.color"
        />
        <span class="font-weight-bold ml-75 mr-25">{{ stock.device }}</span>
        <span>- {{ stock.percentage }}%</span>
      </div>
    </div>
    <!--/ stocks -->
  </b-card>
</template>

<script>
import { $themeColors } from '@themeConfig'
import { BCard } from 'bootstrap-vue'
import ChartjsComponentDoughnutChart from './charts-components/ChartjsComponentDoughnutChart.vue'

export default {
  components: {
    ChartjsComponentDoughnutChart,
    BCard,
  },
  data() {
    const chartColors = {
      primaryColorShade: '#836AF9',
      yellowColor: '#ffe800',
      successColorShade: '#28dac6',
      warningColorShade: '#ffe802',
      warningLightColor: '#FDAC34',
      infoColorShade: '#299AFF',
      greyColor: '#4F5D70',
      blueColor: '#2c9aff',
      blueLightColor: '#84D0FF',
      greyLightColor: '#EDF1F4',
      tooltipShadow: 'rgba(0, 0, 0, 0.25)',
      lineChartPrimary: '#666ee8',
      lineChartDanger: '#ff4961',
      labelColor: '#6e6b7b',
      grid_line_color: 'rgba(200, 200, 200, 0.2)',
    }

    return {
      // chartjsData,
      doughnutChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          cutoutPercentage: 0,
          legend: { display: false },
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                const label = data.datasets[0].labels[tooltipItem.index] || ''
                const value = data.datasets[0].data[tooltipItem.index]
                const output = ` ${label} : ${value} %`
                return output
              },
            },
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
        },
        data: {
          datasets: [
            {
              labels: ['Recompensas pagas', 'Liberado sem resgate', 'Pendente pagamento '],
              data: [5.88, 35.29, 58.83],
              backgroundColor: [$themeColors.primary, $themeColors.warning, $themeColors.danger],
              borderWidth: 5,
              pointStyle: 'rectRounded',
            },
          ],
        },
      },
      stockData: [
        {
          device: 'Recompensas pagas', symbol: 'CircleIcon', color: 'text-primary', percentage: 5.88,
        },
        {
          device: 'Liberado sem resgate', symbol: 'CircleIcon', color: 'text-warning', percentage: 35.29,
        },
        {
          device: 'Pendente pagamento', symbol: 'CircleIcon', color: 'text-danger', percentage: 58.83,
        },
      ],
    }
  },
}
</script>
