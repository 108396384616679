<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        md="4"
      >
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
        >
          <ecommerce-medal :data="congratulations" />
        </b-overlay>
      </b-col>
      <b-col cols="2">
        <statistic-card-with-area-chart
          icon="UnlockIcon"
          color="warning"
          :statistic="new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(60)"
          statistic-title="Liberado sem resgate"
        />
      </b-col>
      <b-col cols="2">
        <statistic-card-with-area-chart
          icon="XSquareIcon"
          color="danger"
          :statistic="new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(100)"
          statistic-title="Pendente de pagamento"
        />
      </b-col>
      <b-col cols="2">
        <statistic-card-with-area-chart
          icon="CheckIcon"
          color="primary"
          :statistic="new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(100)"
          statistic-title="Recompensas pagas"
        />
      </b-col>
      <b-col cols="2">
        <statistic-card-with-area-chart
          icon="DollarSignIcon"
          color="success"
          :statistic="new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(100)"
          statistic-title="Recompensas em dinheiro"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col cols="4">
        <statistic-card-horizontal
          icon="GiftIcon"
          statistic="50"
          statistic-title="Total recompensas em vouchers"
        />
      </b-col>
      <b-col cols="4">
        <statistic-card-horizontal
          icon="GiftIcon"
          statistic="50"
          color="success"
          statistic-title="Voucher à pagar"
        />
      </b-col>
      <b-col cols="4">
        <statistic-card-horizontal
          icon="GiftIcon"
          statistic="50"
          color="warning"
          statistic-title="Vouchers pagos"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col cols="4">
        <financial-card-list-voucher />
      </b-col>
      <b-col cols="4">
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
        >
          <financial-goal-overview :data="voucherRewards" />
        </b-overlay>
      </b-col>
      <b-col cols="4">
        <ChartjsDoughnutChart />
      </b-col>
    </b-row>

  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'

import {
  BRow, BCol, BOverlay,
} from 'bootstrap-vue'

import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

import { getUserData } from '@/auth/utils'

import { kFormatter } from '@core/utils/filter'
import ChartjsDoughnutChart from './ChartjsDoughnutChart.vue'
import EcommerceMedal from './FinancialMedal.vue'
import FinancialGoalOverview from './FinancialGoalOverview.vue'
import FinancialCardListVoucher from './FinancialCardListVoucher.vue'

export default {
  components: {
    BRow,
    BCol,

    EcommerceMedal,
    FinancialGoalOverview,
    FinancialCardListVoucher,
    StatisticCardWithAreaChart,
    StatisticCardHorizontal,
    ChartjsDoughnutChart,
    BOverlay,
  },
  data() {
    return {
      data: {},
      userData: JSON.parse(localStorage.getItem('userData')),
      statisticsItems: [],
      sharedMethods: [],
      indicators: 0,
      shareds: 0,
      congratulations: {
        name: '',
        saleToday: '0',
      },
      convertionPercentage: {},
      voucherRewards: {
        series: [83],
        toPay: 100,
        paidOut: 83,
      },
      engagement: [0],
      dataTableDtos: [],
      selected: { campaignToken: '', name: 'Todas as Campanhas' },
      option: [],
    }
  },
  computed: {
    ...mapGetters({
      isAPICallInProgress: 'api/isAPICallInProgress',
    }),
  },
  // watch: {
  //   // monitorando a campanha selecionada
  //   selected(newCampaing) {
  //     this.getStatisticsItems({
  //       systemUrl: this.userData.SystemUrl,
  //       campaignToken: newCampaing.campaignToken,
  //     })
  //   },
  // },
  created() {
    // data
    this.$http.get('/idq/data')
      .then(response => {
        this.data = response.data
      })
    const userData = getUserData()
    this.congratulations.name = userData.FullName.split(' ')[0] || userData.username

    // recuperando a lista de campanhas
    this.getListCampaings()

    // chamando api do getCountViews
    this.getStatisticsItems({
      systemUrl: this.userData.SystemUrl,
      campaignToken: '',
    })
  },
  methods: {
    kFormatter,
    getListCampaings() {
      return new Promise((resolve, reject) => {
        useJwt.listCampaing()
          .then(response => {
            const campaingsFiltered = response.data
              .filter(campaing => campaing.status !== 'ARQUIVADA')
              .map(campaing => ({ campaignToken: campaing.token, name: campaing.name }))
            this.option = campaingsFiltered
            this.option.unshift({ campaignToken: '', name: 'Todas as Campanhas' })
            // console.log('campanhas: ', campaingsFiltered)
            return resolve(campaingsFiltered)
          })
          .catch(error => reject(error))
      })
    },
    getStatisticsItems(payload) {
      return new Promise((resolve, reject) => {
        useJwt.statisticsItems(payload)
          .then(response => {
            // console.log('res: ', response)

            // dados de faturamento
            this.congratulations.saleToday = response.data.amount.toString()

            // dados de resumo das campanhas
            this.statisticsItems = [
              {
                icon: 'EyeIcon',
                color: 'light-info',
                title: response.data.views,
                subtitle: 'Visualizações',
                customClass: 'mb-2 mb-xl-0',
              },
              {
                icon: 'MonitorIcon',
                color: 'light-primary',
                title: response.data.uniqueAccess,
                subtitle: 'Acessos únicos',
                customClass: 'mb-2 mb-xl-0',
              },
              {
                icon: 'UserIcon',
                color: 'light-danger',
                title: response.data.leads,
                subtitle: 'Leads',
                customClass: 'mb-2 mb-sm-0',
              },
              {
                icon: 'AwardIcon',
                color: 'light-success',
                title: response.data.conversions,
                subtitle: 'Conversões',
                customClass: '',
              },
            ]

            // estatísticas dos métodos de compartilhamento
            this.sharedMethods = [response.data.whatsApp, response.data.facebook, response.data.email, response.data.other]
            this.$store.commit('dashboard/SET_SHARED_METHODS_VALUES',
              [response.data.whatsApp, response.data.facebook, response.data.email, response.data.other])

            // dados de engajamento
            this.engagement = [response.data.engagement]

            // dados de conversão
            this.convertionPercentage = {
              lead: response.data.leads,
              conversions: response.data.conversions,
              series: [response.data.leadsConvertionPercentage],
            }

            // dados de indicadores e compartilhamentos
            this.indicators = response.data.indicators
            this.shareds = response.data.shareds

            // dados da tabela com detalhes das campanhas
            this.dataTableDtos = response.data.dataTableDtos

            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
